import React from "react"

const FirstLevelBanner = ({ sources }) => {
  return (
    <section className="soundingLouder-firstLevel-banner">
      <img
        src={sources.mobileImage.localFile.publicURL}
        alt="banner-mobile"
        className="img-fluid img-mobile"
      />

      <div
        style={{ backgroundImage: `url('${sources.desktopImage.localFile.publicURL}')` }}
        className="banner-img img-desktop"
      >
        {sources.location && <p>{sources.location}</p>}
      </div>
    </section>
  )
}

export default FirstLevelBanner
