import React from "react"
import MainLayout from '../../../layouts/MainLayout'
import OurWorkComponent from '../../../components/OurWorkComponent'
import OurPartners from '../../../components/OurPartners'
import SEO from "../../../components/SEO"
import seoContents from "../../../constants/seo-contents"
import FirstLevelBanner from "../../../components/our-work/first-level/Banner"
import { graphql } from "gatsby"

const OurWorkOurPartners = ({ data }) => {
  return (
    <MainLayout>
      <SEO title={`${data.strapiPeacePartners.seo.title}`} description={`${data.strapiPeacePartners.seo.description}`} />
      <FirstLevelBanner sources={data.strapiPeacePartners.heroImages} />
      <OurPartners data={data.strapiPeacePartners}/>
      <OurWorkComponent />
    </MainLayout>
  )
}
export default OurWorkOurPartners;

export const pageQuery = graphql`  
  query {
    strapiPeacePartners {
      buttonText
      buttonUrl
      data
      slogan
      header
      sdgContent
      sdgHeader
      seo {
        description
        title
        keywords
      }
      partners {
        image {
          localFile{
            publicURL
          }
        }
      }
      heroImages {
        desktopImage {
          localFile{
            publicURL
          }
        }
        mobileImage {
          localFile{
            publicURL
          }
        }
      }
      logo {
        localFile{
          publicURL
        }
      }
    }
  }
`