import React from 'react'

const OurPartners = ({ data }) => {
    return (
        <section className="our-partners">
            <h2>{data.header}</h2>
            <div className="contents">
                <div>
                    <div className="work-purpose" style={{marginTop:"10px"}}>
                        <p>{data.sdgHeader}</p>
                        <p>
                            {data.sdgContent}
                        </p>
                        <img style={{margin: "44px 0 40px 0"}} src={data.logo.localFile.publicURL} alt={'SDG 17: PARTNERSHIPS'} />
                        <article>
                            {data.data}
                        </article>
                    </div>
                    <a href={data.buttonUrl} className="footer-contribute-button">
                        <span>
                            {data.buttonText}
                        </span>
                    </a>
                    <h3>{data.slogan}</h3>
                </div>
                <ul>
                    {data.partners && data.partners.length > 0 && data.partners.map((e,index) => (
                        <li><img src={`${e.image.localFile.publicURL}`} width={203} height={203} alt={index} /></li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default OurPartners
